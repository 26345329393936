<template>
  <section>
    <div class="content-header">
      <h2>What is intersectionality?</h2>
    </div>
    <div class="content-wrapper">
      <p>Intersectionality is a term that acknowledges that our experience of the world around us is influenced by the <strong>intersection of all of our identities</strong>, and that the contexts we are in influence the ways we experience our identities. </p>
      <p>Kimberlé Crenshaw first coined the term in 1989 to describe how race, class, gender and other individual characteristics “intersect” with one another and overlap. </p>
      <p>This short video, <a href="https://www.youtube.com/watch?v=O1islM0ytkE" target="_blank">What is intersectionality?</a> from Newcastle University describes intersectionality in under 3 minutes!</p>

      <p class="lm">Learn more</p>
      <p>Take another few minutes (18:49 to be exact), to watch Kimberlé Crenshaw’s powerful TED Talk on <a href="https://www.youtube.com/watch?v=akOe5-UsQ2o" target="_blank">“The urgency of intersectionality”</a>.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
